import React, { useState, useCallback, useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import styled, { css } from 'styled-components'

import Theme from '../shared/colors'
import Spacing from '../shared/spacing'
import { typestyle } from '../shared/typography'

const BlockExternalEmbed = ({ title, portal }) => {
    const hasPortal = typeof portal !== 'undefined' && portal !== null

    if (hasPortal) {
        return (
            <Container>
                <div id={'cats-portal-widget'}></div>
                <Helmet
                    script={[
                        {
                            type: 'text/javascript',
                            innerHTML: `window.cjw=window.cjw||function(){(cjw.instance=cjw.instance||[]).push(arguments[0])}; cjw({"id":'${portal}',"domain":"catsone.com","target":"#cats-portal-widget"});`,
                        },
                    ]}
                />

                <Helmet>
                    <script
                        src="https://app.catsone.com/resources/entry-jobwidget.js"
                        type="text/javascript"
                        async="true"
                    />
                </Helmet>
            </Container>
        )
    }
}

const Container = styled.div`
    padding: 4vw;
    width: 100vw;

    ${(props) => {
        props.size = props.size ? props.size : 'body'
        return typestyle()
    }}

    a:link,
    a:visited {
        color: ${Theme.text.link}
    }

    a:hover,
    a:active {
        color: ${Theme.text.hover}
    }
`

export { BlockExternalEmbed as default }
