const size = {
    small: '425px',
    medium: '768px',
    large: '1024px',
    extra: '1440px',
}

export const BREAKPOINTS = {
    mobile: `(min-width: ${size.small})`,
    tablet: `(min-width: ${size.medium})`,
    laptop: `(min-width: ${size.large})`,
    desktop: `(min-width: ${size.extra})`,
}