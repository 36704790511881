import React, { useState } from 'react'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import get from 'lodash/get'

import BlockContentList from './blocks/content-list'
import BlockExternalEmbed from './blocks/external-embed'
import BlockImageWithContent from './blocks/image-with-content'
import BlockVideo from './blocks/video'
import TextCover from './text-cover'

import EntryStandardPage from './entries/standard-page'

import ImageWithSVGSupport from './shared/image-with-svg-support'
import TextLink from './shared/text-link'
import { P, H1, H2, H3, H4, H5, H6, UL, OL, BLOCKQUOTE } from './shared/typography'


const Bold = ({ children }) => <strong>{children}</strong>

// Marks - BOLD, ITALIC, UNDERLINE, CODE

// Blocks - DOCUMENT, PARAGRAPH, HEADING_1 thru 6, OL_LIST, UL_LIST, LIST_ITEM
//  HR, QUOTE, EMBEDDED_ENTRY, EMBEDDED_ASSET

// Inlines - HYPERLINK, ENTRY_HYPERLINK, ASSET_HYPERLINK, EMBEDDED_ENTRY

const options = {
    renderMark: {
        [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => (
            <P>{children}</P>
        ),
        [BLOCKS.HEADING_1]: (node, children) => (
            <TextCover>{children}</TextCover>
        ),
        [BLOCKS.HEADING_2]: (node, children) => (
            <H2>{children}</H2>
        ),
        [BLOCKS.HEADING_3]: (node, children) => (
            <H3>{children}</H3>
        ),
        [BLOCKS.HEADING_4]: (node, children) => (
            <H4>{children}</H4>
        ),
        [BLOCKS.HEADING_5]: (node, children) => (
            <H5>{children}</H5>
        ),
        [BLOCKS.HEADING_6]: (node, children) => (
            <H6>{children}</H6>
        ),
        [BLOCKS.OL_LIST]: (node, children) => (
            <OL>{children}</OL>
        ),
        [BLOCKS.UL_LIST]: (node, children) => (
            <UL>{children}</UL>
        ),
        [BLOCKS.QUOTE]: (node, children) => (
            <BLOCKQUOTE>{children}</BLOCKQUOTE>
        ),
        [BLOCKS.HR]: (node, children) => (
            <div>
                <hr />
            </div>
        ),
        [INLINES.ENTRY_HYPERLINK]: (node, children) => {
            // If you are using contenful.js sdk, the referenced entry is resolved
            // automatically and is available at `node.data.target`.
            // const referencedEntry = getEntryWithId(node.data.target.sys.id);
            // <a href={`/pages/${referencedEntry.fields.slug}`}>{children}</a>
            // return 'test';

            return (
                <TextLink
                    className={styles.link}
                    children={children}
                    node={node.data.target}
                    activeClassName="active"
                />
            )
        },
        [INLINES.ASSET_HYPERLINK]: (node, children) => {
            if (typeof node.data.target.file === 'undefined') {
                return <span></span>
            }

            return (
                <TextLink
                    className={styles.link}
                    children={children}
                    uri={node.data.target.file.url}
                    activeClassName="active"
                />
            )
        },
        [INLINES.HYPERLINK]: (node, children) => {
            // If you are using contenful.js sdk, the referenced entry is resolved
            // automatically and is available at `node.data.target`.
            // const referencedEntry = getEntryWithId(node.data.target.sys.id);
            // <a href={`/pages/${referencedEntry.fields.slug}`}>{children}</a>
            // return 'test';
            return (
                <TextLink
                    className={styles.link}
                    uri={node.data.uri}
                    children={children}
                />
            )
        },
        [BLOCKS.EMBEDDED_ENTRY]: node => <EmbeddedEntry node={node} />,
        [BLOCKS.EMBEDDED_ASSET]: node => {
            const content = node

            const { title, description, file } = content.data.target

            if (typeof file === 'undefined') {
                return <p>Asset Error</p>
            }

            const mimeType = file.contentType
            const mimeGroup = mimeType.split('/')[0]
            const mimeSet = mimeType.split('/')[1]

            switch (mimeGroup) {
                case 'image':
                    const contentfulImage = content.data.target

                    const width = contentfulImage.file.details.image.width
                    const height = contentfulImage.file.details.image.height

                    const ratio = width / height
                    const micro = height <= 200 || width <= 400

                    return (
                        <ImageWithSVGSupport
                            title={content.data.target.title}
                            fluid={contentfulImage.fluid}
                            file={file}
                            svg={contentfulImage.svg}
                        />
                    )
                case 'application':
                    return (
                        <a
                            alt={description ? description : null}
                            href={file.url}
                        >
                            {title ? title : file.details.fileName}
                        </a>
                    )
                default:
                    return (
                        <span
                            style={{ backgroundColor: 'red', color: 'white' }}
                        >
                            {' '}
                            {mimeType} embedded asset{' '}
                        </span>
                    )
            }
        },
    },
}

const blocksHandlers = {
    ContentfulBlockContentList: value => (
        <ContentList node={value} />
    ),
    ContentfulBlockExternalEmbed: value => <BlockExternalEmbed title={value.title} portal={value.careerPortalId} />,
    ContentfulBlockImageWithContent: value => <BlockImageWithContent node={value} />,
    ContentfulBlockVideo: value => <Video node={value} />,
    ContentfulStandardPage: value => <EntryStandardPage node={value} />,
    default: value => <Placeholder value={value} />,
}

const Container = ({ data, isFullWidth, className }) => {
    const hasJSON = data !== null && typeof data !== 'undefined'

    if (!hasJSON) {
        return null
    }

    return (
        <div>
            {renderRichText(data, options)}
        </div>
    )
}

const Placeholder = ({ value }) => {
    return <div>Placeholder</div>
}

function EmbeddedEntry({ node }) {
    if (typeof node.data.target === 'undefined') {
        return <span></span>
    }

    const type =
        typeof node.data.target.sys.contentType === 'undefined'
            ? node.data.target.__typename
            : node.data.target.sys.contentType.sys.id
    const value = get(node, 'data.target')
    const handler = blocksHandlers[type] || blocksHandlers.default

    return <div>{handler(value)}</div>
}

const Video = ({ node }) => {
    return (
        <BlockVideo />
    )
}

export default ({ data, isFullWidth, className }) => (
    <Container className={className} data={data} isFullWidth={isFullWidth} />
)
