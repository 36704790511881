import React from 'react'
import styled, { css } from 'styled-components'

import Theme from '../shared/colors'
import Spacing from '../shared/spacing'
import { BREAKPOINTS } from '../shared/grid'

import ImageWithSVGSupport from '../shared/image-with-svg-support'
import PrimaryContent from '../primary-content'

const BlockImageWithContent = ({ node }, ...rest) => {
    return (
        <Container>
            {node.image && (
                <ImageWithSVGSupport file={node.image.file} fluid={node.image.fluid} />
            )}
            {node.primaryContent && (
                <Content>
                    <PrimaryContent data={node.primaryContent} />
                </Content>
            )}
        </Container>
    )
}

const Container = styled.section`
    display: grid;

    @media ${BREAKPOINTS.mobile} {
        grid-template-columns: 1fr 1fr;
    }
`

const Content = styled.div`
    padding: 10vw;
    background-color: ${Theme.ui.tertiary};

    @media ${BREAKPOINTS.mobile} {
        padding: 4vw;
    }
`

export default BlockImageWithContent