import React from 'react'
import ImageWithSVGSupport from '../shared/image-with-svg-support'

const BlockVideo = ({ title, fluid, file }, ...rest) => {
    return (
        <div>
            <p>Video</p>
        </div>
    )
}

export default BlockVideo