// All spacing in rem's

const baseline = '1.25'
const sizeType = 'rem'

const Spacing = {
    xxxs: `${baseline * 0.125}${sizeType}`,
    xxs: `${baseline * 0.25}${sizeType}`,
    xs: `${baseline * 0.5}${sizeType}`,
    sm: `${baseline * 0.75}${sizeType}`,
    md: `${baseline}${sizeType}`,
    lg: `${baseline * 1.5}${sizeType}`,
    xl: `${baseline * 2}${sizeType}`,
    xxl: `${baseline * 2.5}${sizeType}`,
    xxxl: `${baseline * 3}${sizeType}`,
}

export default Spacing
