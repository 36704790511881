import React from 'react'
import styled, { css } from 'styled-components'

import Theme from './shared/colors'
import Spacing from './shared/spacing'

import { H1, typestyle } from './shared/typography'

const Cover = ({children}) => {

    return (
        <Section>
            <H1>
                {children}
            </H1>
        </Section>
    )
}

const Section = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${Spacing.xxxl} 10vw;
    width: 100vw;
    background-color: ${Theme.brand.primary};
    text-align: center;
    color: ${Theme.text.inverse};

    i {
        display: block;
        font-size: .7em;
        font-style: normal;
    }
`

export default Cover